@import '../../styles/common';
@import '~bootstrap/scss/modal';

$animation-length: 0.35s;
$cutout-radius: 23px;
$attachment-size: 2.25rem;

.modal {
    display: block;
}

.modal-backdrop {
    background-color: var(--modal-backdrop);

    &.show {
        opacity: 0.9;
    }
}

.modal-dialog {
    margin-bottom: 2rem;
    margin-top: 2rem;

    @include media-breakpoint-down(xs) {
        margin-left: 1rem;
        margin-right: 1rem;
    }

    .modal.fade & {
        opacity: 0;
    }

    .modal.show & {
        opacity: 1;
    }
}

.modal-content-wrapper {
    filter: drop-shadow($shadow-large-active);
    pointer-events: auto;
    position: relative;
}

.modal-content {
    border-radius: 10px;
    justify-content: center;
    overflow: hidden;

    &.with-attachment {
        clip-path: polygon(
            0 0,
            100% 0,
            100% 100%,
            circle(
                20,
                0deg,
                $cutout-radius,
                'calc(50% + ',
                ')',
                'calc(100% + ',
                ')',
                true
            ),
            calc(50% - #{$cutout-radius}) 100%,
            0 100%
        );
        padding-bottom: 2rem;
    }
}

.attachment {
    bottom: 0;
    left: 50%;
    line-height: calc(#{$attachment-size} - 4px) !important;
    position: absolute;
    transform: translate(-50%, 50%);
    width: calc(#{$attachment-size} - 4px) !important;
}
