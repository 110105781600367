@import '../../styles/common';

$vertical-padding: 2rem;

.content {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: $vertical-padding 1rem;

    h1 {
        @include serif;

        color: var(--heading-color);
        font-size: 1.625rem;
    }

    .body {
        text-align: center;
    }

    .button {
        margin-top: $margin-around-buttons;

        &:last-child {
            margin-bottom: $margin-around-buttons - $vertical-padding;
            margin-top: $margin-between-buttons;
        }
    }
}
