@import '../../styles/common';

.header {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 1rem 1rem 1.25rem;
    width: 100%;

    .headline {
        @include serif();

        font-size: 1.625rem;
        font-weight: bold;
    }

    .sub {
        font-family: $font-family-sans-serif;
        margin-bottom: 1.25rem;
    }

    .avatar {
        border-radius: 50%;
        box-shadow: 0 6px 18px rgba(0, 0, 0, 0.3);
        height: 7.625rem;
        width: 7.625rem;
    }
}

.explanation {
    margin: 2.5rem 2rem 1rem;
    text-align: center;
}

.disclaimer {
    margin-bottom: 2rem;
    text-align: center;
}

.button-container {
    display: flex;
    justify-content: center;
    margin-bottom: 1.75rem;
}
