@import '../../styles/common';
@import '~bootstrap/scss/progress';

.container {
    align-items: center;
    display: flex;
    flex-direction: row;
    margin-top: 0.5rem;
}

.percentage {
    @include serif;

    color: var(--primary);
    margin-inline-start: 0.5rem;
}

// Adjust Bootstrap progress bar.
.progress {
    overflow: visible !important;
    transition-duration: 0.35s;
    width: 100%;
}

.progress-bar {
    $progress-bar-height: $progress-height + 2px;

    background-image: $progress-bar-bg;
    border-radius: $progress-bar-height;
    height: $progress-bar-height;
    margin-top: -1px;
}
