@import '../../styles/common';

.content {
    padding: 2rem 2rem 0;

    h1 {
        @include serif;

        color: var(--heading-color);
        font-size: 1.625rem;
    }

    ul {
        padding-inline-start: 0;

        li {
            margin-bottom: 1rem;
        }
    }

    .info-container {
        align-items: center;
        display: flex;
    }

    .item-explanation {
        margin-bottom: 0;
        margin-left: 1rem;

        &.small {
            font-size: 0.8rem;
            margin-top: 0.2rem;
        }
    }

    .repetition-state-indicator {
        flex-shrink: 0;
        margin-top: 0;
    }
}