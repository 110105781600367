@import '../../styles/common';

.alert {
    border-inline-start: 2px solid transparent;
    font-style: italic;
    margin-bottom: $spacer * 2;
    margin-top: $spacer * 2;
    padding-inline-start: 0.5rem;

    &.success {
        border-inline-start-color: var(--success);
    }

    &.error {
        border-inline-start-color: var(--danger);
    }
}
