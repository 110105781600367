@import '../../styles/common';

.logo-login-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 15.125rem;
    justify-content: flex-end;
    overflow: hidden;
    position: relative;
    width: 100%;

    @include media-breakpoint-down(sm) {
        height: 13rem;
    }

    .logo {
        margin-bottom: 2rem;
        max-height: 60px;
        max-width: 300px;
        position: relative;
        z-index: 3;
    }
}
