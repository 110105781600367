@import '../../styles/common';

.router-with-navigation-bar {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding-block-start: $navbar-sm-height;

    @include media-breakpoint-down(xs) {
        padding-block-end: $navbar-xs-height;
        padding-block-start: 0;
    }
}

.router-with-item-navigation-bar {
    padding-bottom: $navbar-xs-height;
}

.on-fire {
    --font-family-serif: 'Montserrat', serif;
}

.route {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}
