@import 'auth';

.idp-description {
    margin: $margin-between-blocks-and-texts 0 0;
}

.sso-buttons {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-bottom: $margin-around-buttons;

    .sso-button:nth-child(n + 2) {
        margin-top: $margin-between-blocks;
    }
}
