@import '../../styles/common';

$border-color: #fff;

// Global style settings
.navigation-bar {
    @include make-container();

    background-color: var(--navbar-bg);
    display: flex;
    inset-inline-start: 0;
    position: fixed;
    z-index: $zindex-modal-backdrop - 1;

    .content {
        @include make-col(12);
        @include make-container-max-widths();

        align-items: center;
        display: flex;
        justify-content: center;
        margin: 0 auto;
    }

    .menu-item {
        align-items: center;
        color: var(--navbar-inactive);
        display: flex;
        flex-direction: row;
        font-weight: lighter;
        justify-content: center;
        position: relative;
        transition: filter 0.15s ease;

        &,
        &:active {
            outline: none;
        }

        &:hover,
        &:focus {
            color: var(--navbar-front);
            text-decoration: none;
        }

        &.active {
            &,
            &:hover,
            &:focus {
                color: var(--navbar-front);
            }
        }

        .menu-item-label {
            font-size: 0.888rem;
            text-transform: lowercase;
        }
    }

    // Smartphone style
    @include media-breakpoint-down(xs) {
        bottom: 0;
        box-shadow: 0 -3px 15px rgba(0, 0, 0, 0.1);
        height: $navbar-xs-height;
        padding-bottom: var(--safe-area-inset-bottom);
        width: 100%;

        .logo {
            display: none;
        }

        .menu-item {
            flex-direction: column;
            flex-grow: 1;
            height: 100%;

            svg {
                height: 1.5rem;
                width: 1.5rem;
            }

            &:nth-child(n + 3)::after {
                $line-size: $navbar-xs-height-base * 0.3;

                background-color: rgba(255, 255, 255, 0.2);
                border-radius: 1px;
                content: '';
                display: block;
                height: $line-size;
                inset-inline-start: 0;
                position: absolute;
                top: calc(($navbar-xs-height-base - $line-size) / 2);
                width: 1px;
            }
        }
    }

    // Tablet and desktop style
    @include media-breakpoint-up(sm) {
        box-shadow: 3px 0 15px rgba(0, 0, 0, 0.1);
        display: flex;
        height: $navbar-sm-height;
        top: 0;
        width: 100%;

        .logo {
            height: 1.375rem;
            margin-inline-end: auto;
        }

        .menu-item {
            height: $navbar-sm-height;
            margin-inline-start: 1.5rem;

            svg {
                height: 1.125rem;
                margin-inline-end: 0.375rem;
                width: 1.125rem;
            }
        }
    }
}
