.loading-indicator {
    animation: fade-in-loading-indicator 0.5s ease 0.75s;
    animation-fill-mode: backwards;
    color: var(--heading-color);
    display: flex;
    justify-content: center;
    margin: 3rem 0;
}

@keyframes fade-in-loading-indicator {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
