// Define colors.
$primary: #2e8996;
$secondary: #185b6c;
$success: #1fc364;
$warning: #d6b500;
$danger: #ff2219;

$body-bg: #eef7f7;
$body-color: #000;

$gray-disabled: #d0d0d0;
$gray-disabled-accent: #a2a2a2;

// Heading colors.
$heading-color: #185b6c;
$subheading-color: #fc6142;

// Links.
$link-color: var(--primary, $primary);
$link-hover-color: $link-color;

// Reusable shadows.
$shadow-small-inactive: 0 3px 5px rgba(0, 0, 0, 0.05);
$shadow-small-active: 0 5px 10px rgba(0, 0, 0, 0.15);
$shadow-large-inactive: 0 3px 5px rgba(0, 0, 0, 0.1);
$shadow-large-active: 0 5px 15px rgba(0, 0, 0, 0.15);
$shadow-large-hover: 0 7px 15px rgba(0, 0, 0, 0.25);

// Standardised margins we use.
$margin-above-header-avatar: 1rem;
$margin-around-buttons: 2rem;
$margin-between-blocks-and-texts: 2rem;
$margin-between-blocks: 1.25rem;
$margin-between-blocks-with-attachments: 2rem;
$margin-between-buttons: 1.25rem;
$margin-between-heading-and-text: 0.75rem;
$margin-between-inline-text-and-icon: 0.666rem;

// Fonts and sizes.
$font-family-sans-serif: 'Open Sans', -apple-system, BlinkMacSystemFont,
    'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
$font-family-serif: 'Roboto Slab', serif;
$font-family-base: $font-family-sans-serif;

$font-size-base: 1rem; // 16px

// Navigation bar.
$navbar-xs-height-base: 64px;
$navbar-xs-height: calc(
    #{$navbar-xs-height-base} + var(--safe-area-inset-bottom)
);
$navbar-sm-height: 50px;

// Progress bar.
$progress-height: 5px;
$progress-bg: #d3d3d3;

$progress-bar-bg: linear-gradient(to right, var(--primary), var(--secondary));

$standard-border-radius: 1rem;

// Same as the default grid, minus the `xl` size.
$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
);
$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
);

// Form related.
$custom-control-indicator-checked-bg: var(--primary);
$custom-control-indicator-checked-color: #fff;
$input-color: var(--text-accent-color);
$input-focus-border-color: var(--primary);
$input-height: 3.75rem;
