@import '../styles/common';

.header {
    @include serif;

    color: var(--secondary-contrast);
    display: flex;
    min-height: 150px;
    position: relative;

    >.container {
        @include make-container();
        @include make-container-max-widths();

        pointer-events: none;
        z-index: 2;

        >.row {
            @include make-row();

            justify-content: center;
            pointer-events: all;
        }

        &.sticky {
            left: 0;
            position: fixed;
            right: 0;
        }

    }

    a,
    a:hover,
    a:active,
    a:focus {
        color: var(--secondary-contrast);
    }

    a:hover,
    a:active,
    a:focus {
        outline: none;
        text-decoration: underline;
    }

    &.sticky {
        pointer-events: none;
        //position: sticky;
        //top: 0;
        //z-index: 10;
    }
}

.backdrop {
    background-color: var(--secondary);
    bottom: 0;
    clip-path: ellipse(130% 248% at 50% -150%);
    left: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;

    &.sticky {
        height: 150px;
        position: fixed;
    }

    &.flat {
        clip-path: none;
    }

    &.duel {
        svg {
            height: 100%;
            left: 0;
            pointer-events: none;
            position: absolute;
            right: 0;
            top: 0;
            width: 100%;

            path {
                fill: var(--primary);
            }

            @supports (object-fit: cover) {
                height: initial;
                left: initial;
                object-fit: cover;
                position: initial;
                right: initial;
                top: initial;
                width: initial;
            }

            [dir='rtl'] & {
                transform: scaleX(-1);
            }
        }

        &.stream-item {
            // Fix Safari repeat issue
            min-height: 250px;
        }
    }

    .gradient,
    .graphic {
        height: 100%;
        width: 100%;
    }

    .graphic {
        background-image: url('../../../../images/header-image.jpg');
        background-position: center center;
        background-size: cover;
    }

    .gradient {
        background-image: linear-gradient(to bottom right,
                rgba(var(--primary-rgb), var(--header-gradient-opacity-top-left)),
                rgba(var(--secondary-rgb),
                    var(--header-gradient-opacity-bottom-right)));
    }

    @include media-breakpoint-down(xs) {
        clip-path: ellipse(150% 248% at 50% -150%);
    }
}

.header-content,
.header-content-wide {
    @include make-col-ready();
    @include make-col(12);

    padding-top: calc(#{$spacer} + var(--safe-area-inset-top));

    @include media-breakpoint-only(xs) {
        @include make-col(12);
    }
}

.header-content {
    @include media-breakpoint-up(md) {
        @include make-col(10);

        &-wide {
            @include make-col(10);
        }
    }

    @include media-breakpoint-up(lg) {
        @include make-col(8);

        &-wide {
            @include make-col(10);
        }
    }
}