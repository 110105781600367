@import 'common';
@import 'theming';
@import 'add-to-homescreen';
@import 'offline-indicator';

@import '~bootstrap/scss/bootstrap';

@import '~@openfonts/montserrat_all/index';
@import '~@openfonts/open-sans_all/index';
@import '~@openfonts/roboto-slab_all/index';

html,
body {
    min-height: 100vh;
    user-select: none;
}

body {
    background-color: var(--body-bg);
    display: flex;
    flex-direction: column;
    // We never want a horizontal scrollbar.
    overflow-x: hidden;
    text-align: start;
}

// This is the root element React mounts into.
:global(#app) {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

:root {
    // Assign safe area insets to variables and make those available.
    --safe-area-inset-top: 0px;
    --safe-area-inset-right: 0px;
    --safe-area-inset-bottom: 0px;
    --safe-area-inset-left: 0px;

    @supports (top: constant(safe-area-inset-top)) {
        --safe-area-inset-top: constant(safe-area-inset-top);
        --safe-area-inset-right: constant(safe-area-inset-right);
        --safe-area-inset-bottom: constant(safe-area-inset-bottom);
        --safe-area-inset-left: constant(safe-area-inset-left);
    }

    @supports (top: env(safe-area-inset-top)) {
        --safe-area-inset-top: env(safe-area-inset-top);
        --safe-area-inset-right: env(safe-area-inset-right);
        --safe-area-inset-bottom: env(safe-area-inset-bottom);
        --safe-area-inset-left: env(safe-area-inset-left);
    }
}
