@import '../../styles/common';

$default-vertical-padding: 2rem;

.content {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: $default-vertical-padding 1rem;

    h1 {
        @include serif;

        color: var(--heading-color);
        font-size: 1.625rem;
        margin: 0;
    }

    .body {
        text-align: center;

        p {
            margin: 0;
        }

        p:first-child {
            margin-top: $margin-between-heading-and-text;
        }
    }

    .primary-button {
        margin-top: $margin-around-buttons;
    }

    .secondary-button {
        margin-bottom: $margin-around-buttons - $default-vertical-padding;
        margin-top: $margin-between-buttons;
    }
}
