@import '../pages/auth/auth';

.form-container {
    background-color: inherit;
    margin: 0;
}

.bubble-list {
    font-family: $font-family-sans-serif;
    font-size: 1rem;

    .header {
        font-weight: bold;
    }

    .list-item {
        display: flex;
        margin-bottom: 0.625rem;

        .bubble {
            align-self: start;
            font-size: 1.125rem;
            margin-inline-end: 0.625rem;
        }

        .bubble-content {
            align-self: center;
        }

        .bubble-correct {
            color: var(--success);
        }

        .bubble-false {
            color: var(--danger);
        }

        .bubble-warn {
            color: var(--warning);
        }
    }
}
