@import '../../styles/common';

.number-bubble {
    background-color: var(--primary);
    border-radius: 50%;
    color: var(--secondary-contrast);
    flex-shrink: 0;
    font-weight: bold;
    height: 32px;
    line-height: 32px;
    overflow: hidden;
    text-align: center;
    width: 32px;

    .smaller {
        font-size: 0.666em;
    }

    .invisible {
        // opacity doesn't work in Firefox, fill-opacity doesn't work in IE.
        fill-opacity: 0;
        opacity: 0;
    }

    svg {
        display: block;
        height: 100%;
        width: 100%;
    }

    text {
        fill: currentColor;
    }
}
