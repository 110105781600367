@import '../../styles/common';

.content {
    padding: 2rem 1rem 0;
    text-align: center;

    h1 {
        color: var(--heading-color);
        font-size: 1.625rem;
    }

    p {
        margin-bottom: 2rem;
    }

    .note {
        font-weight: bold;
        margin-bottom: 0;
    }
}
