@import '../../styles/common';

$contrast: 10%;

$thumb-inner-color: var(--primary);
$thumb-inner-size: 10px;
$thumb-outer-color: rgba(var(--primary-rgb), 0.26);
$thumb-outer-size: 33px;
$thumb-radius: 50%;

$track-color: #d3d3d3;
$track-height: 8px;
$track-radius: calc($track-height / 2);
$track-shadow: none;
$track-width: 100%;

@mixin slider-track {
    box-shadow: $track-shadow;
    cursor: pointer;
    height: $track-height;
    width: $track-width;
}

@mixin slider-progress {
    background-image: linear-gradient(
        to right,
        var(--secondary),
        var(--primary)
    );
    border-radius: $track-radius;
    height: $track-height;
}

@mixin slider-handle {
    $outer-distance: calc(($thumb-inner-size / $thumb-outer-size) * 100%);

    background-color: transparent;
    background-image: radial-gradient(
        $thumb-inner-color,
        // Without the +-1%, the line is very hard and ugly.
        $thumb-inner-color #{$outer-distance - 2%},
        $thumb-outer-color #{$outer-distance + 2%},
        $thumb-outer-color
    );
    border-radius: $thumb-radius;
    border-width: 0;
    cursor: pointer;
    height: $thumb-outer-size;
    margin-inline-start: var(--thumb-margin);
    position: relative;
    width: $thumb-outer-size;
}

.slider {
    appearance: none;
    background: none;
    border: 0;
    box-shadow: none;
    width: $track-width;

    &:focus {
        outline: none;
    }

    &::-webkit-slider-runnable-track {
        @include slider-track;

        background: $track-color
            linear-gradient(
                to right,
                var(--secondary),
                var(--primary) var(--current-value),
                $track-color var(--current-value)
            );
        border-radius: $track-radius;
    }

    &::-webkit-slider-thumb {
        @include slider-handle;

        appearance: none;
        margin-top: calc(($track-height / 2) - ($thumb-outer-size / 2));
    }

    &::-moz-focus-outer {
        border: 0;
    }

    &::-moz-range-track {
        @include slider-track;

        background: $track-color;
        border-radius: $track-radius;
    }

    &::-moz-range-progress {
        @include slider-progress;
    }

    &::-moz-range-thumb {
        @include slider-handle;

        transform: translateX(var(--thumb-margin));
    }

    &::-ms-track {
        @include slider-track;

        background: transparent;
        border-color: transparent;
        border-width: $thumb-outer-size 0;
        color: transparent;
    }

    &::-ms-fill-lower,
    &:focus::-ms-fill-lower {
        @include slider-progress;
    }

    &::-ms-fill-upper {
        background: $track-color;
        border-radius: $track-radius * 2;
    }

    &::-ms-tooltip {
        display: none;
    }

    &::-ms-thumb {
        @include slider-handle;

        // Edge
        @supports (-ms-ime-align: auto) {
            transform: translateY(7px);
        }
    }
}
