@import '../../styles/common';

.content {
    padding: 2rem 1rem 0;
    text-align: center;

    h1 {
        @include serif;

        color: var(--heading-color);
        font-size: 1.625rem;
    }

    p {
        margin: 2rem;
    }

    .button {
        display: block;
        margin: auto;
        margin-bottom: 2rem;
    }
}
