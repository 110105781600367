@import '../../styles/common';

.legend {
    color: var(--text-accent-color);
    font-size: 1rem;
    font-style: italic;
    margin-bottom: 1rem;
    text-align: center;
}

.rounds {
    width: 100%;
}

.spacer {
    height: 5px;
}

.round {
    background-image: linear-gradient(
        to right,
        rgba(255, 255, 255, 0) 0%,
        rgba(211, 211, 211, 0.25) 50%,
        rgba(255, 255, 255, 0) 100%
    );
    height: 36px;

    td {
        color: #a2a2a2;
        text-align: center;

        &.time-out {
            color: var(--warning);
        }

        &.correct {
            color: var(--success);
            font-weight: bold;
        }

        &.wrong {
            color: var(--danger);
        }
    }
}

.result,
.round-number {
    width: 10%;
}

.result {
    .result-indicator {
        background-color: #a2a2a2;
        border-radius: 50%;
        color: $white;
        display: inline-block;
        font-size: 0.75rem;
        height: 21px;
        line-height: 21px;
        text-align: center;
        width: 21px;
    }

    &.correct .result-indicator {
        background-color: var(--success);
    }

    &.wrong .result-indicator {
        background-color: var(--danger);
    }

    &.time-out .result-indicator {
        background-color: var(--warning);
    }

    &.mine {
        font-weight: bold;
    }
}

.round-number .circle {
    display: inline-block;
}

.time {
    width: 35%;
}
