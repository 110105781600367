@import '../../styles/common';

.versus-header {
    --attachment-padding: 22px;
    --attachment-size: 2.5rem;

    .players {
        @include safe-fallback(justify-content, space-evenly, space-between);
        @include serif;

        display: flex;
        flex-direction: row;
        padding-bottom: 3rem;

        @include media-breakpoint-down(xs) {
            justify-content: space-between;
            padding-bottom: 2rem;
        }

        .player {
            align-items: center;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;

            .avatar {
                position: relative;

                img {
                    background-color: $gray-disabled;
                    border-radius: 50%;
                    box-shadow: 0 6px 18px rgba(0, 0, 0, 0.3);
                    height: 120px;
                    width: 120px;

                    @include media-breakpoint-down(xs) {
                        height: 75px;
                        width: 75px;
                    }
                }

                .attachment {
                    align-items: center;
                    border-radius: 50%;
                    bottom: 0;
                    display: flex;
                    height: var(--attachment-size);
                    justify-content: center;
                    position: absolute;
                    text-align: center;
                    width: var(--attachment-size);
                }
            }

            .name {
                font-weight: bold;
                margin-top: 0.5rem;
            }

            &:first-child .name.with-attachment {
                padding-inline-start: var(--attachment-padding);
            }

            &:last-child .name.with-attachment {
                padding-inline-end: var(--attachment-padding);
            }

            &:first-child .avatar.with-attachment {
                padding-inline-start: var(--attachment-padding);

                .attachment {
                    background-color: var(--primary);
                    inset-inline-start: 0;
                }
            }

            &:last-child .avatar.with-attachment {
                padding-inline-end: var(--attachment-padding);

                .attachment {
                    background-color: var(--secondary);
                    color: var(--secondary-contrast);
                    inset-inline-end: 0;
                }
            }
        }

        .middle {
            align-items: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }

    &.active,
    &.play,
    &.invited {
        .middle {
            .vs {
                font-size: 3.25rem;
                font-weight: bold;
                line-height: 100%;
                text-align: center;
            }
        }
    }

    &.invited,
    &.play,
    &.lost,
    &.draw,
    &.won {
        .status {
            text-align: center;

            h1 {
                @include serif;

                font-size: 1.625rem;
            }

            p {
                font-family: $font-family-sans-serif;
            }
        }
    }

    &.lost,
    &.draw,
    &.won {
        --attachment-padding: 40px;
        --attachment-size: 55px;

        .middle {
            justify-content: center;

            .vs {
                font-size: 3.25rem;
                font-weight: bold;
                line-height: 100%;
                text-align: center;
            }
        }

        .player .attachment {
            background-color: var(--success) !important;
            border: 2px solid $white;
            color: $white !important;
            font-size: 1.75rem;
        }
    }

    &.lost {
        .player:first-child img {
            height: 90px;
            width: 90px;
        }

        .player:last-child img {
            height: 50px;
            width: 50px;
        }
    }

    &.won {
        .player:first-child img {
            height: 50px;
            width: 50px;
        }

        .player:last-child img {
            height: 90px;
            width: 90px;
        }
    }

    &.draw {
        --attachment-padding: 32px;
        --attachment-size: 45px;

        @include media-breakpoint-down(xs) {
            --attachment-padding: 20px;
        }

        .player .avatar {
            img {
                height: 75px;
                width: 75px;
            }

            .attachment {
                background-color: var(--warning) !important;
            }
        }

        .player:first-child .half-icon {
            clip-path: polygon(0 0, 50% 0, 50% 100%, 0 100%);
            transform: translateX(10%);
        }

        .player:last-child .half-icon {
            clip-path: polygon(50% 0, 100% 0, 100% 100%, 50% 100%);
            transform: translateX(-10%);
        }
    }
}
