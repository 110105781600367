@import '../../styles/common';

.select-item-outer {
    background: white;
    border: 1px solid #ced4da;
    border-radius: 0.625rem;
    box-shadow: $shadow-small-active;
    margin-bottom: 1rem;

    &:focus,
    &:hover {
        outline: none;

        .title {
            text-decoration: underline;
        }
    }
}

.select-item {
    align-items: center;
    color: var(--text-color);
    cursor: pointer;
    display: flex;
    font-style: normal;
    padding: 1.4375rem 0;

    &.inside {
        padding: 1rem 0.75rem;
    }

    .icon {
        color: var(--primary);
        font-size: 1.125rem;
        inset-inline-start: 0.75rem;
        margin-inline-end: 0.7rem;
    }

    .title {
        flex-grow: 1;
    }

    .icon-right {
        color: var(--button-primary-bg);
        margin-inline-start: 0.7rem;
    }

    &:active,
    &:focus,
    &:hover {
        color: var(--text-color);
        outline: none;
        text-decoration: underline;
    }
}

.select-list {
    background: white;
    border-top: 1px solid #ced4da;
    list-style: none;
    padding-inline-start: 1.6rem;
    padding-top: 0.875rem;
    width: 100%;

    li {
        cursor: pointer;

        &.selected {
            color: var(--primary);
        }

        &:not(:last-child) {
            margin-bottom: 0.75rem;
        }

        &:focus,
        &:hover {
            color: var(--primary);
        }
    }
}