@import 'buttons';

.primary-button {
    @include button;

    background-color: var(--button-primary-bg);
    color: var(--button-primary-front);
    will-change: background-color, color, box-shadow, text-shadow;

    &:disabled {
        background-color: $gray-disabled;

        &,
        &:hover {
            color: $white;
        }
    }

    &:not(:disabled) {
        &:active,
        &:focus,
        &:hover {
            background-color: var(--button-primary-bg-focus);
            color: var(--button-primary-front);
        }
    }
}
