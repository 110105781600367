@import '../../styles/common';

.popup {
    text-align: center;

    h1 {
        font-size: 1.444rem;
        margin-bottom: $margin-between-heading-and-text;
    }

    .explanation {
        margin: 2rem 2rem 0;
    }

    .circle {
        background-color: $white;
        border-radius: 50%;
        color: $dark;
        font-size: 3rem;
        height: 6rem;
        line-height: 6rem;
        margin: 0 auto 1rem;
        text-align: center;
        width: 6rem;
    }

    .button {
        margin: $margin-around-buttons auto;
    }
}
