@import '../../styles/common';

.popup {
    text-align: center;

    h1 {
        font-size: 1.444rem;
    }

    p {
        font-family: $font-family-sans-serif;
        font-size: 1rem;
    }
}

.explanation {
    margin: 2rem 1rem 1rem;
}

.circle {
    background-color: $white;
    border-radius: 50%;
    color: $danger;
    font-size: 3rem;
    height: 6rem;
    line-height: 6rem;
    margin: 0 auto 1.5rem;
    text-align: center;
    width: 6rem;
}
