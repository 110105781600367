@import '../../styles/common';

.outer-cropper {
    margin: 1rem 1rem 2rem;
}

.content {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 1.5rem;

    h1,
    h2 {
        text-align: center;
    }

    h1 {
        @include serif;

        color: var(--heading-color);
        font-size: 1.5rem;
    }

    h2 {
        font-size: 1rem;
    }

    .scale-slider {
        align-items: center;
        display: flex;
        margin-top: 1rem;

        .size {
            color: var(--heading-color);

            &.small {
                font-size: 0.75rem;
                margin-inline-end: 1rem;
            }

            &.large {
                font-size: 1.25rem;
                margin-inline-start: 1rem;
            }
        }
    }

    .buttons {
        @include safe-fallback(justify-content, space-evenly, space-between);

        display: flex;
        flex-direction: row;
        width: 282px;
    }

    .input {
        display: none;
    }

    @include media-breakpoint-down(xs) {
        .buttons {
            width: 222px;
        }
    }
}
