@mixin button-container($top-padding: 2rem, $bottom-padding: $top-padding) {
    display: flex;
    justify-content: center;
    margin-bottom: $bottom-padding;
    margin-top: $top-padding;
}

@mixin flex-center {
    align-items: center;
    display: flex;
    justify-content: center;
}

@mixin serif {
    font-family: var(--font-family-serif);
    font-weight: bold;
    line-height: 1.2;
}

@mixin safe-fallback($property, $value, $fallback-value) {
    #{$property}: $fallback-value;

    @supports ($property: $value) {
        #{$property}: $value;
    }
}

@mixin avatar-attachment($size: 2.875rem, $bottom-offset: -11px) {
    background-color: var(--button-primary-bg);
    border-radius: 50%;
    color: var(--button-primary-front);
    font-family: $font-family-sans-serif;
    font-size: 0.888rem;
    font-weight: bold;
    height: $size;
    inset-block-end: $bottom-offset;
    inset-inline-end: -3px;
    line-height: $size;
    position: absolute;
    text-align: center;
    width: $size;
}

@mixin dynamic-color-mix(
    $property,
    $r-var,
    $g-var,
    $b-var,
    $mix-with,
    $percentage
) {
    $mix-with-r: red($mix-with);
    $mix-with-g: green($mix-with);
    $mix-with-b: blue($mix-with);

    // Reference: https://github.com/sass/libsass/blob/master/src/fn_colors.cpp#L148
    $p: calc($percentage / 100%);
    $w: calc(2 * $p - 1);
    $a: 0; // alpha 1 - alpha 2, we assume both are 100%

    $w1: calc(((($w + $a) / (1 + $w * $a)) + 1) / 2);
    $w2: 1 - $w1;

    #{$property}: rgb(
        calc(#{$w1} * var(#{$r-var}) + #{$w2 * $mix-with-r}),
        calc(#{$w1} * var(#{$g-var}) + #{$w2 * $mix-with-g}),
        calc(#{$w1} * var(#{$b-var}) + #{$w2 * $mix-with-b})
    );
}
