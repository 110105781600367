@import '../../styles/common';

.search-result {
    align-items: center;
    background-color: $white;
    border-radius: 10px;
    box-shadow: $shadow-small-active;
    color: var(--heading-color);
    display: flex;
    flex-direction: row;
    margin-top: 1.25rem;
    outline: none;
    padding: 0.625rem;

    &:not(.unavailable) {
        cursor: pointer;

        &:focus,
        &:hover {
            .indicator {
                color: var(--button-primary-bg-focus);
            }
        }
    }

    &.unavailable {
        box-shadow: $shadow-small-inactive;

        .indicator {
            color: $gray-500;
        }
    }

    .avatar {
        background-color: $gray-disabled;
        border-radius: 50%;
        flex-shrink: 0;
        height: 3rem;
        margin-inline-end: 0.625rem;
        overflow: hidden;
        width: 3rem;

        img {
            width: 100%;
        }
    }

    .details {
        border-inline-end: 1px solid var(--navbar-front);
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        padding-right: 0.625rem;

        .name {
            @include serif;
        }

        .rating {
            font-size: 0.888rem;
        }
    }

    .indicator {
        align-items: center;
        color: var(--button-primary-bg);
        display: flex;
        font-size: 1.5rem;
        justify-content: center;
        padding-left: 1rem;
        padding-right: 1rem;
        position: relative;

        @include media-breakpoint-only(xs) {
            padding-inline-start: 0.625rem;
            padding-right: 0;
        }

        .icon {
            background-color: var(--danger);
            border-radius: 50%;
            bottom: 0;
            color: $white;
            font-size: 0.5rem;
            height: 1rem;
            line-height: 1rem;
            position: absolute;
            right: 0;
            text-align: center;
            transform: translate(-50%, 50%);
            width: 1rem;
        }
    }

    &.invited .indicator .icon {
        background-color: var(--success);
    }

    &.placeholder {
        color: $gray-disabled;

        .details {
            border-inline-end--color: $gray-disabled;
        }

        .icon {
            display: none;
        }
    }
}
