@import '../../styles/common';
@import '~bootstrap/scss/tooltip';

.content {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 1.5rem;

    h1,
    h2 {
        text-align: center;
    }

    h1 {
        @include serif;

        color: var(--heading-color);
        font-size: 1.5rem;
    }

    h2 {
        font-size: 1rem;
    }

    img {
        border-radius: 50%;
        height: 282px;
        margin: 1.5rem 0;
        width: 282px;
    }

    .buttons {
        @include safe-fallback(justify-content, space-evenly, space-between);

        display: flex;
        flex-direction: row;
        position: relative;
        width: 282px;
    }

    .tooltip {
        top: 0;
        transform: translate(22%, -100%);

        .arrow {
            left: 50%;
            transform: translateX(-50%);
        }

        @include media-breakpoint-up(sm) {
            transform: translate(27%, -100%);
        }
    }

    .input {
        display: none;
    }

    @include media-breakpoint-down(xs) {
        img {
            height: 222px;
            width: 222px;
        }

        .buttons {
            width: 222px;
        }
    }
}
