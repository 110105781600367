@import '../../styles/common';

.repetitionstate-indicator {

    position: relative;

    .dropshadow-small {
        border-radius: 50%;
        box-shadow: $shadow-small-active;
        height: 100%;
        position: absolute;
        width: 100%;
    }

    .avatar {
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);

        img {
            box-shadow: none;
            height: auto;
            margin-top: 0;
            width: 100%;
        }
    }

    .outer-circle {
        //these are the color and opacity values as used in the xd
        fill: #f1f7f8;
        opacity: 0.1;

        &.small {
            fill: #fff;
            opacity: 1;
        }
    }
}