// NOTE: The CSS vars defined below will probably be overwritten during actual
// usage by loading /style/style.css, which contains the same CSS vars, with the
// values as defined in the theme in the CMS.
// This file serves only to set the default values, in case the style.css file
// is not loaded successfully.
:root {
    $navbar-bg: #0d3342;

    // Theming variables, on top of the ones already defined by Bootstrap.
    --body-bg: #{$body-bg};
    --text-color: #{$body-color};
    --text-accent-color: #0d3342;
    --heading-color: #{$heading-color};
    --subheading-color: #{$subheading-color};
    --subheading-color-rgb: #{red($subheading-color)},
        #{green($subheading-color)}, #{blue($subheading-color)};
    --secondary-contrast: #{$white};
    --navbar-bg: #{$navbar-bg};
    --navbar-bg-rgb: #{red($navbar-bg)}, #{green($navbar-bg)},
        #{blue($navbar-bg)};
    --navbar-front: #ff905b;
    --navbar-inactive: #d3d3d3;
    --modal-backdrop: #{$navbar-bg};
    --modal-backdrop-rgb: #{red($navbar-bg)}, #{green($navbar-bg)},
        #{blue($navbar-bg)};

    // Button types.
    --button-primary-bg: #fc6142;
    --button-primary-bg-focus: #ff905b;
    --button-primary-front: #{$white};

    --button-secondary-border-color: #fc6142;
    --button-secondary-border-color-focus: #ff905b;
    --button-secondary-text: #{$secondary};
    --button-secondary-text-focus: #{$primary};

    --button-tertiary-bg: #{$primary};
    --button-tertiary-bg-focus: #{$secondary};
    --button-tertiary-front: #{$white};

    // Gradients use these values.
    --primary-rgb: #{red($primary)}, #{green($primary)}, #{blue($primary)};
    --secondary-rgb: #{red($secondary)}, #{green($secondary)},
        #{blue($secondary)};

    // Not actually themable.
    --font-family-serif: $font-family-serif;
}
