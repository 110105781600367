@import '../../styles/common';
@import '~bootstrap/scss/type';

.code {
    max-height: 33vh;
    overflow: auto;
    word-break: break-all;
}

.button-container {
    align-items: center;
    display: flex;
    flex-direction: column;
}

.button {
    margin: $margin-around-buttons auto;
}
