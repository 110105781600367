@import '../../styles/common';
@import '~bootstrap/scss/buttons';

@mixin button($size: 2rem, $shadows: true, $text-shadow: $shadows) {
    @extend .btn;

    border: 2px solid transparent;
    border-radius: $size;
    margin-bottom: 0; // This is mainly for when using a different HTML element.
    padding: 0.625rem $size 0.75rem;
    transition: background-color 0.2s ease, border-color 0.2s ease,
        box-shadow 0.2s ease, color 0.2s ease;

    &:disabled {
        box-shadow: none;
        cursor: default;
        opacity: 1;
    }

    @if $text-shadow {
        text-shadow: 0 2px 4px rgba(0, 0, 0, 0.16);

        &:disabled {
            text-shadow: none;
        }
    }

    @if $shadows {
        box-shadow: $shadow-small-active;

        &:not(:disabled) {
            &:active,
            &:focus,
            &:hover {
                box-shadow: $shadow-small-active;
            }
        }
    }
}

.icon-button {
    border-radius: 50%;
    box-sizing: content-box;
    line-height: 2.5rem;
    // I'd rather not use !important here, but the padding defined in the mixin above overrides this.
    padding: 0 !important;
    width: 2.5rem;
}
