@import '../../styles/common';

.content {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 2rem;

    h1 {
        @include serif;

        color: var(--heading-color);
        font-size: 1.444rem;
        text-align: center;
    }

    h1,
    p {
        margin-bottom: 1.27rem;
    }

    p {
        text-align: center;
    }
}
