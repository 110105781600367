@import '../../styles/common';

.error-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: 5rem 2rem;

    .icon {
        color: var(--danger);
        font-size: 5rem;
    }

    .heading {
        @include serif;

        color: var(--heading-color);
        font-size: 2.375rem;
        margin-top: 1.75rem;
    }

    p {
        margin-bottom: 3rem;
        max-width: 40rem;
        text-align: center;
    }
}
