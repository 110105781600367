@import '../styles/common';

.container {
    @include make-container();
    @include make-container-max-widths();

    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.row {
    @include make-row();

    justify-content: center;
}

.page,
.page-narrow,
.page-wide {
    @include make-col-ready();

    position: initial;

    @include media-breakpoint-only(xs) {
        @include make-col(12);
    }
}

.page {
    @include media-breakpoint-up(md) {
        @include make-col(10);

        &-narrow {
            @include make-col(8);
        }

        &-wide {
            @include make-col(10);
        }
    }

    @include media-breakpoint-up(lg) {
        @include make-col(8);

        &-narrow {
            @include make-col(6);
        }

        &-wide {
            @include make-col(10);
        }
    }
}

.watermark {
    margin-top: auto;
    padding: $margin-between-blocks 0;
    text-align: center;

    img {
        max-height: 50px;
        max-width: 250px;
        opacity: 0.08;
    }
}
