@import '../../styles/common';

.empty-list {
    :first-child {
        opacity: 0.8;
    }

    :nth-child(2) {
        opacity: 0.6;
    }

    :nth-child(3) {
        opacity: 0.4;
    }
}
