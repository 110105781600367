@import '../../../styles/common';
@import '~bootstrap/scss/forms';
@import '~bootstrap/scss/custom-forms';

.form-header {
    margin-bottom: 2.5rem;
    text-align: center;

    .head {
        @include serif;

        color: var(--heading-color);
        font-size: 2.375rem;
        margin-top: 1.75rem;
    }

    .sub {
        color: var(--subheading-color);
        font-family: $font-family-sans-serif;
        font-size: 1.5rem;
        font-weight: bold;
        margin-top: -0.25rem;
    }
}

.form-container {
    margin: 0 1rem;

    $prepend-padding: 2.5rem;

    .form-control {
        border-radius: $standard-border-radius;
        box-shadow: $shadow-small-active;
        margin: 0 auto;
    }

    .prepend {
        position: relative;
    }

    .prepend-padding {
        padding-inline-start: $prepend-padding;
    }

    .prepend-icon {
        align-items: center;
        color: var(--primary);
        display: flex;
        font-size: 1rem;
        height: 100%;
        inset-inline-start: 1px; // Border size of the form controls.
        justify-content: center;
        position: absolute;
        width: $prepend-padding;
    }

    .checkbox-lg .custom-control-label::before,
    .checkbox-lg .custom-control-label::after {
        height: 1.375rem;
        inset-inline-start: -1.5rem;
        left: initial;
        top: 0;
        width: 1.375rem;
    }

    .checkbox-lg .custom-control-label {
        color: var(--text-accent-color);
        font-size: 0.875rem;
        padding-inline-start: 0.625rem;
        padding-top: 0;
    }
}

.form-footer {
    margin: 2rem 0;
    text-align: center;

    .primary-button {
        margin-bottom: 3.75rem;
    }

    .form-footer-link {
        color: var(--text-color);
        font-size: 0.875rem;
        text-decoration: underline;
    }
}

.custom-control {
    padding-inline-start: 1.5rem;
    padding-left: initial;
}

.custom-control-input {
    inset-inline-start: 0;
    left: initial;
}
