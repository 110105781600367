@import '../../styles/common';

.logo-container {
    text-align: center;

    .logo {
        margin: $margin-between-blocks 0;
        max-height: 50px;
        max-width: 250px;
    }
}
