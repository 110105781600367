$red: #ec8787;
$green: #d6e9c6;
$yellow: #f8ecad;

$darkRed: #551313;
$darkGreen: #468847;
$darkYellow: #7c6d1f;

:global {
    @keyframes offline-rotation {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(359deg);
        }
    }

    @keyframes offline-dropin {
        0% {
            opacity: 0;
            transform: translateY(0);
        }

        1% {
            opacity: 0;
            transform: translateY(800px);
        }

        2% {
            opacity: 1;
            transform: translateY(800px);
        }

        100% {
            opacity: 1;
            transform: translateY(0);
        }
    }

    @keyframes offline-dropout {
        0% {
            transform: translateY(0);
        }

        100% {
            transform: translateY(800px);
        }
    }

    .offline-ui {
        background: #fff;
        border-radius: 4px;
        bottom: 0.5rem;
        display: none;
        font-size: 0.888rem;
        inset-inline-start: 0.5rem;
        margin: auto;
        max-width: 100%;
        overflow: hidden;
        padding: 0.5rem 1rem;
        position: fixed;
        z-index: 2000;

        .offline-ui-content {
            padding-inline-start: 1.2rem;
        }

        &.offline-ui-up {
            &.offline-ui-up-5s {
                display: block;
            }

            .offline-ui-content {
                &::before {
                    content: 'Online';
                }

                &::after {
                    background-color: $darkGreen;
                    border-radius: 50%;
                    bottom: 0;
                    content: ' ';
                    display: block;
                    height: 0.8em;
                    inset-inline-start: 1em;
                    margin: auto;
                    position: absolute;
                    top: 0;
                    width: 0.8em;
                }
            }
        }

        &.offline-ui-down {
            display: block;

            .offline-ui-content {
                &::before {
                    content: 'Offline';
                }

                &::after {
                    background-color: $darkRed;
                    border-radius: 50%;
                    bottom: 0;
                    content: ' ';
                    display: block;
                    height: 0.8em;
                    inset-inline-start: 1em;
                    margin: auto;
                    position: absolute;
                    top: 0;
                    width: 0.8em;
                }
            }
        }

        &.offline-ui-up {
            animation: offline-dropout forwards 0.5s 2s;
            background: $green;
            color: $darkGreen;
        }

        &.offline-ui-down {
            animation: offline-dropin 0.5s;
            background: $red;
            color: $darkRed;

            &.offline-ui-connecting {
                .offline-ui-content::after {
                    animation: offline-rotation 0.7s linear infinite;
                    background-color: transparent;
                    border: 2px solid rgba(0, 0, 0, 0);
                    border-left-color: $yellow;
                    border-radius: 50%;
                    border-top-color: $yellow;
                    content: ' ';
                    display: block;
                    height: 0.8em;
                    margin: auto;
                    opacity: 0.7;
                    width: 0.8em;
                }
            }
        }
    }
}
