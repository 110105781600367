@import '../../styles/common';

.content {
    display: flex;
    flex-direction: column;
    padding: 1rem;

    h1 {
        @include serif;

        color: var(--heading-color);
        font-size: 1.444rem;
        margin-bottom: 1.27rem;
        text-align: center;
    }

    hr {
        border-top-color: var(--navbar-front);
        margin-bottom: 0;
        width: 100%;
    }
}

.status {
    font-size: 1rem;
    margin-top: 0.5rem;
    text-align: center;
}

.error {
    color: var(--danger);
}

.search-box-outer {
    align-items: center;
    border: 1px solid #d3d3d3;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    padding: 0.75rem 0.5rem;

    .icon {
        color: var(--primary);
        margin: 0 1rem 0 0.5rem;
    }

    input {
        border: 0;
        flex-grow: 1;
        margin: 0;
        min-width: 50px; // Without this the input grows too much on xs screens.
        padding: 0;

        &::-ms-clear {
            display: none;
        }

        &:focus {
            outline: none;
        }
    }

    button {
        box-shadow: none;
        box-sizing: border-box;
        flex-shrink: 0;
        font-size: 85%;
        line-height: calc(1.625rem - 4px);
        width: 1.625rem;

        &:focus,
        &:hover {
            box-shadow: none;
        }
    }
}

.list {
    list-style: none;
    margin-left: 0.625rem;
    margin-right: 0.625rem;
    padding-inline-start: 0;
}

.no-results {
    color: var(--text-accent-color);
    font-style: italic;
    margin-top: 1.25rem;
    text-align: center;
}

.button {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
}
